:root {
--font-family:'Roboto', sans-serif;

--light:300;
--regular:400;
--medium:500;
--bold:700;

/* core fonts / styles */
--font: var(--light) 14px var(--font-family);

--color-background:linear-gradient(to bottom right, 
#192b38, 
#2a5a6d, 
#192b38);
--color-font:white;

--max-width: 1280px;
}

/*17253b*/

body {
  font:var(--font);
  font-size: 16px;
  color: var(--color-font);

  /*background: linear-gradient(180deg, var(--color-background) 0%, rgba(54,54,54,1) 100%) fixed;*/
  background:var(--color-background);
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;

  height:100%;
  width:100%;

  display: flex;
  flex-direction: column;
}

#root {
  height:100%;
  width:100%;

  display: flex;
  
  flex-direction: column; 
  overflow-y: scroll;
}

.app {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  width:100%;
  text-align: center;
}

.headline {
  font-size: x-large;
  font-weight: var(--regular);
}



.features ul {
  list-style: none;
  padding-left: 0;  /* Removes the padding on the left side */
  margin-left: 0;  
}

.features li {
  text-align: left;
  
}

li {
  padding-bottom: 10px;
}

hr {
  height: 1px;       /* Set the height of the line to 1 pixel */
  border: none;      /* Removes the default border */
  background-color: white; /* Sets the color of the line to white */
  margin-top: 0;     /* Optionally, you can remove the top margin */
  margin-bottom: 0;  /* Optionally, you can remove the bottom margin */
}

.underline {

    border-bottom: 1px solid white; /* Adds a solid 1px white border at the bottom */

}

.faq {
  text-align: left;
}

.faqQuestion {
  font-weight: var(--bold);
  text-align: left;
}

.faqAnswer {
  text-align: left;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: var(--max-width);
  gap:30px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:10px;
    align-items: "flex-end";
    padding:10px;
    width:100%;
    border-bottom: 1px solid #CCCCCC;
}

.appTitle {
    font-size: larger;
    font-weight: var(--medium);
}

.links {
}

@media screen and (max-width: 600px) {
  .hideSmall {
    display: none;
    background-color: aqua;
  }
}

.imageDropShadow {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.6));
}

.featurePod {
  color:#192b38;
  background-color: aliceblue;
  text-align: center;
}

.featurePodTitle {
  text-transform: uppercase;
  font-weight: var(--medium);

}

a {
  color: var(--color-font);
  
}

.links a{
  text-decoration-color: #fbfbfb66;
  text-decoration: none;
}

.links a:hover {
  text-decoration-color: #fbfbfb;
  text-decoration: underline;
}

.featureTitle {
  font-weight: var(--medium);
  text-transform: uppercase;
  text-align: left;
}

.hover {
  cursor: pointer; /* Default cursor when not hovering */
}

.hover:hover {
  cursor: pointer; /* Cursor changes to 'pointer' when hovering */
}
